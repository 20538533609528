import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "../locales/messages_en.json";
import hi from "../locales/messages_hi.json";
import gu from "../locales/messages_gu.json";
import ur from "../locales/messages_ur.json";
import bn from "../locales/messages_bn.json";

i18n.use(initReactI18next).init({
    debug: true,
    lng: "en",
    returnObjects: true,
    resources: {
      en: { translation: en },
      hi: { translation: hi },
      gu: { translation: gu },
      ur: { translation: ur },
      bn: { translation: bn }
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
});

export default i18n;