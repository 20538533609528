import { useTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function Product() {

    const { t } = useTranslation();
    const product: any[string] = t("product");

    return(
        <Container fluid="md">
          <br />
          <Row>
            <Col xs="12" style={{ paddingBottom: 30 }}>
                <Card border="secondary">
                    <Card.Body>
                      <Card.Text>
                        {product.description}
                      </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
          </Row>
          <Row>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                <center>
                    <Image src="assets/food-ordering-app.png" rounded style={{ maxWidth: 150, maxHeight: 150, display: "block"}} />
                </center>
              </Col>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                 {product.onlineApplication}
              </Col>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                {product.cardPayment}
              </Col>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                <center>
                    <Image src="assets/card-payment.jpg" rounded style={{ maxWidth: 150, maxHeight: 150, display: "block"}} />
                </center>
              </Col>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                <center>
                    <Image src="assets/order-management.png" rounded style={{ maxWidth: 150, maxHeight: 150, display: "block"}} />
                </center>
              </Col>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                 {product.orderManagement}
              </Col>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                {product.orderPrinter}
              </Col>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                <center>
                    <Image src="assets/gprs-printer.jpg" rounded style={{ maxWidth: 150, maxHeight: 150, display: "block"}} />
                </center>
              </Col>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                <center>
                    <Image src="assets/user-registration.png" rounded style={{ maxWidth: 150, maxHeight: 150, display: "block"}} />
                </center>
              </Col>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                 {product.orderWithoutRegistration}
              </Col>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                {product.orderTracking}
              </Col>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                <center>
                    <Image src="assets/order-tracking.png" rounded style={{ maxWidth: 150, maxHeight: 150, display: "block"}} />
                </center>
              </Col>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                <center>
                    <Image src="assets/table-booking.jpg" rounded style={{ maxWidth: 150, maxHeight: 150, display: "block"}} />
                </center>
              </Col>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                 {product.tableBooking}
              </Col>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                {product.promotions}
              </Col>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                <center>
                    <Image src="assets/discount.png" rounded style={{ maxWidth: 150, maxHeight: 150, display: "block"}} />
                </center>
              </Col>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                <center>
                    <Image src="assets/report.png" rounded style={{ maxWidth: 150, maxHeight: 150, display: "block"}} />
                </center>
              </Col>
              <Col xs="6" style={{ paddingBottom: 10 }}>
                 {product.reporting}
              </Col>
          </Row>
        </Container>
    );
}

export default Product;