import { useTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function ContactUs() {

    const { t } = useTranslation();
    const contactUs: any[string] = t("contactUs");

    return(
        <Container fluid="md">
          <hr />
          <Row>
            <Col xs="12">
                <center>
                    <h2>{contactUs.title}</h2>
                </center>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs="3"></Col>
            <Col xs="2">
                <a aria-label="Call" href="tel:+447714186354">
                    <Image src="assets/icons-telephone-call.png" rounded style={{ maxWidth: 50, maxHeight: 50, display: "block"}} />
                </a>
            </Col>
            <Col xs="2">
                <a aria-label="Chat on WhatsApp" href="https://wa.me/+447448352382">
                    <Image src="assets/whatsapp.png" rounded style={{ maxWidth: 50, maxHeight: 50, display: "block"}} />
                </a>
            </Col>
            <Col xs="2">
                <a aria-label="Email" href="mailto:info@restosys.co.uk">
                    <Image src="assets/email-icon.png" rounded style={{ maxWidth: 50, maxHeight: 50, display: "block"}} />
                </a>
            </Col>
            <Col xs="3"></Col>
          </Row>
        </Container>
    );
}

export default ContactUs;