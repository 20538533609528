import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import Alert from "react-bootstrap/Alert";

function CookieConsent() {

    const { t } = useTranslation();
    const application: any[string] = t("application");

    const [cookies, setCookie] = useCookies(["isCookieConsented"]);

    const handleCookieConsented = () => {
        setCookie("isCookieConsented", true, { path: "/", maxAge: 90 * 24 * 60 * 60 });
    };

    return(
        <>
            { cookies.isCookieConsented !== true &&
                <Alert variant="warning" onClose={handleCookieConsented} dismissible>
                  <center>
                   {application.cookie}
                  </center>
                </Alert>
            }
        </>
    );
}

export default CookieConsent;