import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useCookies } from "react-cookie";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Image } from "react-bootstrap";

function Menu() {

    const { t, i18n } = useTranslation();
    const application: any[string] = t("application");
    const [cookies, setCookie] = useCookies(["appLocale"]);

    const onChangeLanguageHandler = (language: string) => {
        i18n.changeLanguage(language);
        setCookie("appLocale", language, { path: "/", maxAge: 90 * 24 * 60 * 60 });
    };

    useEffect(() => {
        // Set local again on page refresh.
        const cookieAppLocale = cookies.appLocale;
        if (cookieAppLocale) {
            setTimeout(() => {
                onChangeLanguageHandler(cookieAppLocale);
            }, 100);
        }
    }, []);

    return(
        <Navbar expand="lg" sticky="top" data-bs-theme="dark" style={{ backgroundColor: "#6f42c1" }}>
          <Container fluid>
            <Navbar.Brand href="#"><Image src="../assets/logo/android-chrome-192x192.png" title="Logo" alt="Logo" style={{ maxWidth: "100%", height: "25px" }} /> {application.name}</Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
             <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: "100px" }} navbarScroll></Nav>
             <Nav>
                <NavDropdown title={<span><i className="bi bi-globe" /> {application.language} </span>} id="collapsible-nav-dropdown">
                  <NavDropdown.Item href="#" onClick={() => onChangeLanguageHandler("en")}>English</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#" onClick={() => onChangeLanguageHandler("hi")}>हिंदी</NavDropdown.Item>
                  <NavDropdown.Item href="#" onClick={() => onChangeLanguageHandler("gu")}>ગુજરાતી</NavDropdown.Item>
                  <NavDropdown.Item href="#" onClick={() => onChangeLanguageHandler("ur")}>اردو</NavDropdown.Item>
                  <NavDropdown.Item href="#" onClick={() => onChangeLanguageHandler("bn")}>বাংলা</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="#deets"> &nbsp; </Nav.Link>
             </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
       );
}

export default Menu;