import { useTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Customer() {

    const { t } = useTranslation();
    const customer: any[string] = t("customer");

    return(
        <Container fluid="md">
          <hr />
          <Row>
            <Col xs="12">
                <center>
                    <h2>{customer.title}</h2>
                </center>
            </Col>
          </Row>
          <Row>
            <Col xs lg="4" style={{ padding: 10 }}>
                <Card style={{ width: "100%", height: "100%" }}>
                  <center>
                    <Card.Img variant="top" src="assets/veda-logo.png" style={{ maxWidth: 150, maxHeight: 150, display: "block" }} />
                  </center>
                  <Card.Body>
                    <Card.Title>{customer.veda.name}</Card.Title>
                    <Card.Text>
                     {customer.veda.review}
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Col>
            <Col xs lg="4" style={{ padding: 10 }}>
                <Card style={{ width: "100%", height: "100%" }}>
                  <center>
                    <br />
                    <Card.Img variant="top" src="assets/tamra-logo.jpg" style={{ maxWidth: 150, maxHeight: 150, display: "block" }} />
                  </center>
                  <Card.Body>
                    <Card.Title>{customer.tamra.name}</Card.Title>
                    <Card.Text>
                     <br />
                      {customer.tamra.review}
                     <br />
                     <br />
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Col>
            <Col xs lg="4" style={{ padding: 10 }}>
                <Card style={{ width: "100%", height: "100%" }}>
                  <center>
                    <Card.Img variant="top" src="assets/curry-logo.png" style={{ maxWidth: 150, maxHeight: 150, display: "block" }} />
                  </center>
                  <Card.Body>
                    <Card.Title>{customer.curryOnTheCurve.name}</Card.Title>
                    <Card.Text>
                     {customer.curryOnTheCurve.review}
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Col>
            <Col xs lg="4" style={{ padding: 10 }}>
                <Card style={{ width: "100%", height: "100%" }}>
                  <center>
                    <Card.Img variant="top" src="assets/guru-logo.png" style={{ maxWidth: 150, maxHeight: 150, display: "block" }} />
                  </center>
                  <Card.Body>
                    <Card.Title>{customer.guruIndianCuisine.name}</Card.Title>
                    <Card.Text>
                       {customer.guruIndianCuisine.review}
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Col>
            <Col xs lg="4" style={{ padding: 10 }}>
                <Card style={{ width: "100%", height: "100%" }}>
                  <center>
                    <Card.Img variant="top" src="assets/horse-hound-logo.png" style={{ maxWidth: 150, maxHeight: 150, display: "block" }} />
                  </center>
                  <Card.Body>
                    <Card.Title>{customer.horseAndHound.name}</Card.Title>
                    <Card.Text>
                     {customer.horseAndHound.review}
                    </Card.Text>
                  </Card.Body>
                </Card>
            </Col>
            <Col xs lg="4" style={{ padding: 10 }}>
            </Col>
          </Row>
        </Container>
    );
}

export default Customer;