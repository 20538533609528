import { useTranslation } from "react-i18next";

function Footer() {

    const { t } = useTranslation();
    const application: any[string] = t("application");

    const facebookPageHandler = () => {
        window.open("https://www.facebook.com/61567501671736", "_blank");
    }

    return(
        <footer className="page-footer font-small blue pt-4" style={{ marginTop: 20, color: "white", backgroundColor: "#6f42c1" }}>
            <div className="container-fluid text-center text-md-left">
                <div className="row">
                    <div className="col-md-6">
                        <i title="Facebook" className="bi bi-facebook" style={{ fontSize: 30 }} onClick={facebookPageHandler}></i>
                    </div>
                    <div className="col-md-6">
                        <i title={application.backToTop} className="bi bi-arrow-up-square-fill" style={{ fontSize: 30 }} onClick={() => window.location.href="#" }></i>
                    </div>
                </div>
            </div>
            <div className="footer-copyright text-center py-3">
                &copy; {application.copyRights}
            </div>
        </footer>
    );
}

export default Footer;