import Container from "react-bootstrap/Container";

function VideoSection() {

    return(
        <Container fluid>
            <div style={{ width: "100%", height: "50vh", overflow: "hidden" }}>
                <video autoPlay loop muted style={{ width: "100%", height: "100%", objectFit: "cover" }}>
                    <source src="assets/video.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </Container>
    );
}

export default VideoSection;